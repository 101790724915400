import React from "react";
import linkedin from "../assets/linked2.png"

function Footer() {
    return (
    <div className="flex justify-center w-full mt-8 pb-8  bg-blue-900">
        <div className="bg-black mt-6 rounded-xl px-6 py-6 pb-8">
         <h1 className="text-white text-center text-2xl font-bold mt-3 ">Contact Me </h1>
         <h1 className="text-white text-center text-2xl font-bold mt-3 ">Email: more3118@gmail.com</h1>
         <h1 className="text-white text-center text-2xl font-bold mt-3 ">Phone: 469-682-8364</h1>
      </div>
      </div>
    );
  }
  export default Footer;
  