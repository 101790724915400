import react from "react"

function About(){
    return(
        <div className="h-screen bg-cover w-screen bg-green-600">
             <h1 className="text-5xl pt-4 font-bold text-center pt-8">About Me</h1>
             <div className="flex justify-center">
            
              <div className="bg-black rounded-lg  h-1/2 mt-16 ">
                  <h1 className="text-xl pt-4 font-bold text-center text-white mb-8">Bio</h1>
                  <p className=" px-4 my-auto mx-4 text-white text-justify"> A Cloud Enthusiast that loves to learn new things every day.

                    One of the greatest skills that I feel every student should have is to volunteer and give back to their community.  I am currently learning the Google Cloud environment to be cloud agnonstic so that I can deploy any type of cloud based infrastructure to create value for customers.

                    What I have learned from post-secondary education is that it is not about the skills or the knowledge gained, but about resiliency and the ability to jump back from adversity. With practice, all things become easy and therefore I try to practice new things every day.
                  </p>
              </div>
            </div>
        </div>
    );
}


export default About;