//import back from "../assets/back.jpg"
import header from "../assets/header.png";
import shreyas from "../assets/shreyas.jpeg";
import admin from "../assets/admin.png";
import fund from "../assets/fund.png";
import linked from  "../assets/link3.gif";
import Footer from "../components/Footer.js"
import Azure303 from "../assets/az303.png"
import SolutionsArchitect from "../assets/solutionsarchitect.png"
import SQL from "../assets/MySql.svg"
import python from "../assets/python2.png"
import Java from "../assets/java2.svg"
import Linux from "../assets/Linux.png"
import AzurePro from "../assets/azure_pro.png"

import React from "react";
import reactDom from "react-dom";
import { Routes, Route, Outlet, Link } from "react-router-dom";


function Home() {
  return (
    <div className="bg-no-repeat bg-cover h-100 w-screen" style={{ backgroundImage: `url(${header})` }}>
      <header>
        <h1 className="mx-12 mx-auto font-mono pt-12 text-center font-bold text-6xl text-white">Shreyas's Portfolio</h1>
      </header>    
      <main>
        <div className="flex justify-center">
          <img className="my-4 w-64 h-64 rounded-full "src={shreyas}></img>
          </div>
          <div className="flex justify-center py-4">
            <div className="h-32 w-64  bg-black rounded-lg">
              <h1 className="text-xl pt-2 font-bold text-center text-white">About Me</h1>
              <p className="px-2 mx-4 text-white text-center">An open-minded software developer that loves to learn new things every day.</p>
            </div>

        </div>
            <div>
              <h1 className="pt-12 flex justify-center font-mono text-center font-bold text-6xl text-white my-auto ">Certs</h1>
            </div>
            <div className="flex flex-col justify-center sm:flex-row sm:mx-16 md:flex-row lg:justify-evenly pb-16 place-items-center">
            <a href="https://www.credly.com/badges/2c252172-e5c9-4faf-8046-ee3742f84418"><img className="my-4 w-64 h-64 "src={SolutionsArchitect}></img></a>
                <a href="https://www.credly.com/badges/34d5aed5-acac-435a-9de0-71bc53ba1ff6"><img className="my-4 w-64 h-64 "src={fund}></img></a>
                <a href="https://www.credly.com/badges/455fb9e3-4524-4c16-8879-651cddd8d2c3"><img className="my-4 w-64 h-64 "src={admin}></img></a>
                <a href="https://www.credly.com/badges/37e3e6cd-dbdb-4ef4-9367-b6c37bf98a5e"><img className="my-4 w-64 h-64 "src={AzurePro}></img></a>
                
            </div>
            <div>
              <h1 className="pt-16  flex justify-center font-mono text-center font-bold text-6xl text-white ">Tools</h1>
            </div>
            <div className="flex flex-col justify-center sm:flex-row sm:mx-16 md:flex-row lg:justify-evenly pb-16 place-items-center">
                <div ><img className="my-4 w-64 h-64 "src={SQL}></img>
                <h1 className="pt-4 mt-8 mb-8 my-4 w-64 h-12 bg-black text-white text-center rounded-md">MySQL (RDS Database)</h1>
                </div>
                <div ><img className="my-4 w-64 h-64 "src={python}></img>
                <h1 className="pt-4 mt-8 mb-8 w-64 h-12 bg-black text-white text-center rounded-md">Python 3</h1>
                </div>
                <div ><img className="my-4 w-64 h-64 "src={Java}></img>
                <h1 className="pt-4 mt-8 mb-8 w-64 h-12 bg-black text-white text-center rounded-md">JAVA 11</h1>
                </div>
                <div ><img className="my-4 w-64 h-64 "src={Linux}></img>
                <h1 className="pt-4 mt-8 mb-8 w-64 h-12 bg-black text-white text-center rounded-md">GNU/Linux</h1>
                </div>
            </div>
       </main>
      <Footer/>
    </div>
  );
}

export default Home;
