import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link
} from "react-router-dom";
import Home from "./pages/Home.js"
import Hobby from "./pages/Hobby.js";
import Projects from "./pages/Projects.js";
import Resume from "./pages/Resume"
import Minecraft from "./pages/Minecraft"
import Volunteer from "./pages/Volunteer.js";
import About from "./pages/About.js";

export default function App() {
  return (
    <Router>
      <div>
        <nav>
          <ul className="flex flex-row bg-gray-500 text-white">
            <li className="h-16 w-16 text-bold mx-6 pt-4 font-bold">
              <Link to="/">Home</Link>
            </li>
            {/*<li className="h-16 w-16 text-bold mx-6 pt-4 font-bold">
              <Link to="/about">About</Link>
  </li>*/}
            {/*<li className="h-16 w-16 text-bold mx-6 pt-4 font-bold">
              <Link to="/hobby">Hobby</Link>
  </li> */}
          </ul>
        </nav>

        {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
        <Routes>
          <Route path="/" element={<Home/>}/>
          <Route path="/about" element={<About/>}/>
          <Route path="/hobby" element={<Hobby/>}/>
          <Route path="/minecraft" element={<Minecraft/>}/>
          <Route path="/projects" element={<Projects/>}/>
          
        </Routes>
      </div>
    </Router>
  );
}

