import react from "react"
import friends from "../assets/friends.jpg"
import comethealthdemo1 from  "../assets/comet_healthdemo1.jpg"
import comethealthdemo2 from  "../assets/comet_healthdemo2.jpg"


import React from "react";
import reactDom from "react-dom";
import { Routes, Route, Outlet, Link } from "react-router-dom";



function Projects(){
    return(
        <div className="h-full bg-cover w-screen bg-yellow-500">
            <div>
            <h1 className="mx-auto my-auto font-bold text-center text-4xl p-8">Comet Health</h1>
                <div className="flex justify-center">
                <iframe width="560" height="315" src="https://www.youtube.com/embed/xWgMGYSJO4Q" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen="true"></iframe>
                
                </div>
                <div className="flex flex-col justify-center sm:flex-row sm:mx-16 md:flex-row lg:justify-evenly pb-16 place-items-center mt-16">
                    <a className="py-4" href="https://github.com/more3118/Comet-Health"><button className="h-20 w-32 bg-red-800 text-white rounded-xl hover:bg-red-700 ">Github</button></a>
                    <a className="py-4" href="https://www.youtube.com/watch?v=OfibbnoQutE"><button className="h-20 w-32 bg-red-800 text-white rounded-xl hover:bg-red-700 ">Video Sim</button></a>
                    <a className="py-4" href="https://devpost.com/software/comet-health"><button className="h-20 w-32 bg-red-800 text-white rounded-xl hover:bg-red-700">Devpost</button></a>
                </div>
                <div className="flex justify-evenly text-center text-4xl font-bold pb-16 ">
                    <h1 className="flex justify-evenly text-center text-4xl font-bold">Some Pictures!</h1></div>
                    <div classname="">
                        <div className="flex justify-evenly flex-col sm:flex-row sm:mx-16 md:flex-row lg:justify-evenly pb-16 place-items-center ">
                            <img  src={friends} className="my-4 w-96 h-96 white rounded-md border-black border-4"></img>
                            <img  src={comethealthdemo1} className="my-4 w-96 h-96 white  rounded-md border-black border-4"></img>
                            <img  src={comethealthdemo2} className="my-4 w-96 h-96 white  rounded-md border-black border-4"></img>
                            
    
                    </div>
                    </div>
            </div>
        </div>
    );
}


export default Projects;