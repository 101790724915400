
import react from "react"
import { Link } from "react-router-dom";

function Hobby(){
    return(
        <div className="h-screen w-screen bg-blue-600">
            <h1>Hobby</h1>
            <h1>Minecraft:SBAS</h1>
            <Link to="/minecraft"><button>Minecraft</button></Link>
        </div>
    );
}


export default Hobby;